import React, { useEffect, useState } from "react"
import { BackTop, Button, Tabs, Skeleton, Tag } from "antd"
import QRCode from "qrcode.react"
import {
  PREVIEW_TYPE,
  USER_THEME_TYPE_DESIGN,
  USER_THEME_TYPE_BUY,
} from "../../utils/constant"

import {
  getThemeDetail,
} from "../../api/theme"
import Layout from "../../layouts"

import pc from "../../icons/pc.svg"
import mobile from "../../icons/mobile.svg"

import { DollarTwoTone } from "@ant-design/icons"

import "./id.css"
import "./basic.css"

const { TabPane } = Tabs

export default () => {
  const [themeData, setThemeData] = useState({
    id: "",
    coinItemId: "",
    name: "",
    cover: "",
    description: "",
    authorName: "",
    exampleHtml: "",
    html: "",
    userThemeId: "",
    userThemeType: "",
    isPublic: "",
    updateTime: "",
    themeId: "",
    price: "",
  })
  const [dataState, setDataState] = useState("loading")
  const [previewType, setPreviewType] = useState("")

  useEffect(() => {
    // 避免构建失败
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    initThemeDetail()
  }, [])

  const initThemeDetail = async () => {
    const matchReg = /^\/themes\/id\/(.+)/
    const match = window.location.pathname.match(matchReg)
    if (match) {
      const res = await getThemeDetail(match[1])
      if (res && res.data.data) {
        setThemeData(res.data.data)
        setDataState("loaded")
      } else {
        setDataState("error")
      }
    } else {
      setDataState("error")
    }

    if (window.localStorage.getItem(PREVIEW_TYPE) === null) {
      window.localStorage.setItem(PREVIEW_TYPE, "mobile")
    }
    const type = window.localStorage.getItem(PREVIEW_TYPE)
    setPreviewType(type)
  }

  const handlePreviewType = () => {
    // 避免构建失败
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    const type = window.localStorage.getItem(PREVIEW_TYPE)
    if (type === "mobile") {
      window.localStorage.setItem(PREVIEW_TYPE, "pc")
      setPreviewType("pc")
    } else {
      window.localStorage.setItem(PREVIEW_TYPE, "mobile")
      setPreviewType("mobile")
    }
  }

  const operations = (
    <Button onClick={handlePreviewType}>
      <img alt="" src={previewType === "mobile" ? mobile : pc} />
    </Button>
  )

  const renderArticle = (html, url, type) => {
    let res
    if (type === "mobile") {
      res = (
        <div className="color-preview">
          <div className="nice-wx-box">
            <section
              id="nice"
              className="preview-image-container"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
          {url && (
            <div className="qrcode-container">
              <div className="qrcode">
                <QRCode value={url} />
              </div>
              <p className="qrcode-desc">扫码手机预览文章</p>
            </div>
          )}
        </div>
      )
    } else {
      res = (
        <div className="color-preview">
          <div className="nice-wx-box-pc">
            <section
              id="nice"
              className="preview-image-container"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      )
    }
    return res
  }

  const handleModifyTheme = event => {
    // 避免构建失败
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    event.stopPropagation()
    window.open(`/themes/upload?fixThemeId=${themeData.themeId}`)
  }

  const loading = dataState === "loading"

  const { userThemeId, userThemeType, isPublic, price } = themeData

  let tag = null
  let button = null

  // 自己设计的公开主题
  if (userThemeId && userThemeType === USER_THEME_TYPE_DESIGN && isPublic) {
    tag = <Tag color="green">公开</Tag>
    button = (
      <>
        <Button onClick={handleModifyTheme}>修改主题</Button>
        <strong style={{ marginLeft: "15px" }}>定价：{price} 墨点</strong>
      </>
    )
  }

  // 已购买的主题
  if (userThemeId && userThemeType === USER_THEME_TYPE_BUY) {
    tag = <DollarTwoTone style={{ marginRight: "5px" }} />
  }
  return (
    <Layout
      isNavbarDisplay={false}
      isSpacerDisplay={false}
      isFooterDisplay={false}
      title={themeData.name}
    >
      <div className="theme-detail-container">
        {/* <div className="theme-detail-header">
          <PageHeader title="全部主题" onBack={() => navigate("/themes")} />
        </div> */}
        <BackTop />
        <div className="theme-detail-content">
          <section className="theme-profile-container">
            <div className="theme-profile">
              <div className="theme-cover">
                {!loading ? (
                  <img
                    src={themeData.cover}
                    alt="theme-cover-img"
                    className="theme-cover-img"
                  />
                ) : (
                  <Skeleton.Avatar
                    style={{ height: 200, width: 250 }}
                    active
                    shape="square"
                  />
                )}
              </div>
              <div className="profile-content">
                <Skeleton loading={loading}>
                  <p className="theme-title">
                    {tag}
                    {themeData.name}
                  </p>
                  <p className="theme-author">
                    <span>{themeData.authorName}</span>
                    <span className="theme-time">
                      {themeData.updateTime.split("T")[0]}
                    </span>
                  </p>
                  <p className="theme-description">{themeData.description}</p>
                  <a className="theme-agreement" target="_blank" href="/article/user/theme/">使用该主题排版即同意本说明 &gt;&gt;</a>
                </Skeleton>
                {loading ? <Skeleton.Button active /> : button}
              </div>
            </div>
          </section>
          <div className="available-color">
            <Tabs
              className="tabs"
              // defaultActiveKey="1"
              type="card"
              tabBarExtraContent={operations}
            >
              {themeData.html ? (
                <TabPane tab="作者文章" key="1">
                  {renderArticle(
                    themeData.html,
                    themeData.article,
                    previewType
                  )}
                </TabPane>
              ) : null}
              {themeData.exampleHtml ? (
                <TabPane tab="语法示例文章" key="2">
                  {renderArticle(themeData.exampleHtml, null, previewType)}
                </TabPane>
              ) : null}
              {!themeData.html && !themeData.exampleHtml && (
                <TabPane tab="语法示例文章" key="3">
                  <div className="color-preview">
                    <Skeleton.Avatar
                      style={{ height: "70vh", width: "375px" }}
                      shape="square"
                      active
                    />
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  )
}
